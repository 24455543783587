
import styled from 'styled-components';
import Footer from './Footer';


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensures the page fills the whole viewport
`;

const ContentContainer = styled.div`
  flex: 1; // Ensures it takes up all available space
  display: flex;
  flex-wrap: wrap;
`;

const Quadrant = styled.div`
  flex: 1;
  min-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  height: 900px;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const ParagraphQuadrant = styled(Quadrant)`
  background-color: #FAC0D5;
  opacity: .4;
`;

const ImageQuadrant = styled(Quadrant)`
  background-size: cover;
  background-position: center;
`;

const Title = styled.h1`
  font-size: 60px; // Adjust font size as needed
  font-weight: bold;
  text-align: center;
  margin: 20px; // Adds space around the title
  width: 100%;
  max-width: 300px; // Ensures the text aligns center properly within its quadrant
`;

const ContactLink = styled.a`
  display: block;
  font-size: 30px;
  margin: 10px 0; // Adjust spacing as needed
  color: #000; // Example link color, adjust as needed
  text-decoration: none; // Removes underline from links

  &:hover {
    text-decoration: underline; // Adds underline on hover for better UX
  }
`;

const BoldText = styled.span`
  font-size: 40px;
  font-weight: bold;
  display: block; // Ensures it's on a new line
  margin: 10px 0; // Adjust spacing as needed
`;

const AddressText = styled.span`
  font-size: 30px;
  display: block; // Ensures it's on a new line
  margin: 10px 0; // Adjust spacing as needed
`;

const ContactList = styled.ul`
  list-style-type: none; // Removes bullet points
  padding: 0; // Removes paddi
  text-align: center;
`;
const MapContainer = styled.div`
  margin-top: 20px; // Adds space above the map
  text-align: center; // Centers the iframe if it's not full width
  iframe {
    border: 0; // Removes default iframe border
    width: 100%; // Adjusts width to fill its container
    height: 300px; // Adjusts height, can be customized
    border-radius: 10px; // Optional: adds rounded corners
  }
`;


const ContactPage = () => {
  return (
    <PageContainer>
      <ContentContainer>
        <ParagraphQuadrant>
          <Title>
            Get in touch with Jess
          </Title>
        </ParagraphQuadrant>
        <ImageQuadrant style={{backgroundImage: 'url(/tall.jpg)'}} />
        <ImageQuadrant style={{backgroundImage: 'url(/flowers.jpg)'}} />
        <ParagraphQuadrant>

          <ContactList>
             <li>
              <ContactLink >Contact Me Today!</ContactLink>
            </li>
            <li>
              <ContactLink href="tel:+1234567890">+1 (234) 567-890</ContactLink>
            </li>

            <li>
              <BoldText>
                <ContactLink href="mailto:jess@example.com">jess@example.com</ContactLink>
              </BoldText>
            </li>
            <li>
              <AddressText>1234 Example St, City, 12345</AddressText>
              <MapContainer>
              <iframe
                title='Location Map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6350.1344761630335!2d-107.01040812507958!3d37.26983264108753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x873ddb62195433c1%3A0x8462695b14a11507!2s286%20Lewis%20St%2C%20Pagosa%20Springs%2C%20CO%2081147!5e0!3m2!1sen!2sus!4v1710645090069!5m2!1sen!2sus"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0">
              </iframe>
            </MapContainer>

            </li>
          </ContactList>
        </ParagraphQuadrant>
      </ContentContainer>
      {/* Footer would be a sibling to ContentContainer and outside it, ensuring it's not cut off */}
      <Footer/>
    </PageContainer>
  );
};

export default ContactPage;
